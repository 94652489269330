import React from "react"
import { Link } from "gatsby"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"
import { P, H2, HeaderDivider } from "./page-elements"
import { FeatureList } from "./feature-list"
import Features from "../content/feature-section-content.json"
import { VisibleElement } from "./shared/visible-element"
import { slideLeft } from "./shared/keyframes"
import Image from "../components/image"
const Section = loadable(() => import("./shared/section"))
const EmailContactForm = loadable(() => import("./shared/email-contact-form"))

const IconLink = styled(Link)`
  ${tw`w-6 mx-1`}
`

const Content = {
  title: "Contact us",
  text: "We'd love to hear from you! Call us any time to get started today!",
}

interface ScheduleSectionProps {
  bgColor: string
}

const ScheduleSection: React.FC<ScheduleSectionProps> = ({ bgColor }) => {
  return (
    <Section classes={bgColor} size="lg">
      <Row>
        <VisibleElement>
          {({ visible }: { visible: boolean }) => (
            <Column
              className={`my-8 md:my-0 md:mr-10 animated ${
                visible ? "visible" : ""
              }`}
            >
              <H2 color="darker">{Content.title}</H2>
              <HeaderDivider />
              <P color="dark">{Content.text}</P>
              <div className="flex my-4">
                <IconLink
                  to="https://www.facebook.com/glacierpainting/"
                  target="_blank"
                >
                  <Image src="facebook.png" alt="facebooksocial" />
                </IconLink>
                <IconLink
                  to="https://www.pinterest.com/glacierpainting/"
                  target="_blank"
                >
                  <Image src="pinterest.png" alt="Pinterest Social" />
                </IconLink>
              </div>
              <FeatureList features={Features} />
            </Column>
          )}
        </VisibleElement>
        <Column>
          <EmailContactForm />
        </Column>
      </Row>
    </Section>
  )
}

const Row = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  md:mx-auto
  w-full
  max-w-screen-xl
`

const Column = styled.div`
  ${tw`
    px-4
    md:px-0
    flex-1
    flex
    flex-col
    overflow-hidden
  `}
  &.animated {
    opacity: 0;
    &.visible {
      animation-name: ${slideLeft};
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
    }
  }
`

export default ScheduleSection
